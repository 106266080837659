import { Link } from "react-router-dom";
import NextProjectShader from "./NextProjectShader";

const projects = [
    {
        destiny: 'sysnot',
        title : 'SYSNOT',
        year : 2024,
        description: 'SOLUTIONS',
        startColor : '0.09, 0.50, 0.11, 1.0', // Green
        endColor : '1.0, 1.0, 1.0, 1.0' ,  // White

        to: '/work/sysnot'
    },
    {
        destiny: 'tsmorales',
        title : 'TS MORALES',
        year : 2024,
        description: 'STUDIO',
        startColor : '0.14, 0.35, 0.76, 1.0', // Blue
        endColor : '1.0, 1.0, 1.0, 1.0' ,  // White
        
        to: '/work/tsmorales'
    },
    {
        destiny: 'cerm',
        title : 'CERM AUTOMOTIVE',
        year : 2023,
        description: 'SOLUTIONS',
        startColor : '0.58, 0.08, 0.08, 1.0', // Red
        endColor : '1.0, 1.0, 1.0, 1.0' ,  // White
        
        to: '/work/cerm'
    },
    {
        destiny: 'mercyfit',
        title : 'MERCYFIT',
        year : 2023,
        description: 'SOLUTIONS',
        startColor : '0.8, 0.09, 0.48, 1.0', // Red
        endColor : '1.0, 1.0, 1.0, 1.0' ,  // White
        
        to: '/work/mercyfit'
    },
]

export default function NextProject({destiny}){
    const destinyObj = projects.find((project) => project.destiny === destiny);

    return(
        <Link className="nextProjectImageContainer" to={destinyObj.to}>
            
            <NextProjectShader startColor={destinyObj.startColor} endColor={destinyObj.endColor} />
            <h1 className="nextPorjectTitle">
                {destinyObj.title}
            </h1>
            <div className="nextPorjectInfoContainer">
                <p>
                    NEXT PROJECT
                </p>
                <h2>
                    {destinyObj.description}
                </h2>
            </div>
        </Link>
    );  
};